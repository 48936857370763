.verifyPhoneContainer {
    background-color: #ffffff;
    max-width: 500px;
    margin: 0 auto;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.plusOne {
    padding-left: 10px;
}

.subtitle {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
}

.label {
    font-size: 16px;
    color: #555;
    margin-top: 10px;
    display: block;
}

.phoneInputContainer {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 0;
    margin-top: 5px;
    border-radius: 8px;
    overflow: hidden;
}

.flagBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-right: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;
}

.flagIcon {
    width: 24px;
    height: 24px;
}

.countryCodeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-right: 1px solid #ccc;
    background-color: #f9f9f9;
}

.phoneInput {
    flex: 1;
    font-size: 16px;
    color: black;
    padding: 10px;
    border: none;
    outline: none;
}

.input {
    width: 100%;
    border: 1px solid #CCC;
    border-radius: 8px;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
}

.submitButton {
    margin-top: 20px;
    background-color: #C7F100;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.submitButtonDisabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.errorText {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .verifyPhoneContainer {
        padding: 15px;
        max-width: 100%;
    }

    .title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .subtitle {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .label {
        font-size: 14px;
        margin-top: 8px;
    }

    .phoneInputContainer {
        margin-top: 4px;
    }

    .flagBox,
    .countryCodeBox {
        padding: 8px;
    }

    .flagIcon {
        width: 20px;
        height: 20px;
    }

    .phoneInput {
        font-size: 14px;
        padding: 8px;
    }

    .input {
        padding: 8px;
        margin-top: 4px;
        font-size: 14px;
    }

    .submitButton {
        margin-top: 15px;
        padding: 12px;
        font-size: 14px;
    }

    .errorText {
        font-size: 12px;
        margin-top: 4px;
    }
}
