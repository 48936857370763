.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px; /* Updated height to 15px */
    background: linear-gradient(to right, #C7F100 0%, #C7F100 50%, #ddd 50%, #ddd 100%); /* Updated color to #C7F100 */
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 5px;
    border: none;
    margin: 10px 0;
    position: relative;
    /* Needed for the color fill */
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px; /* Updated height to 15px */
    width: 15px; /* Updated width to 15px */
    background: #C7F100; /* Updated color to #C7F100 */
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    /* Shadow for some depth */
    position: relative;
    z-index: 2;
    /* Raise thumb above the track */
}

.slider::-moz-range-thumb {
    height: 15px; /* Updated height to 15px */
    width: 15px; /* Updated width to 15px */
    border-radius: 50%;
    background: #C7F100; /* Updated color to #C7F100 */
    cursor: pointer;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.slider::-ms-thumb {
    height: 15px; /* Updated height to 15px */
    width: 15px; /* Updated width to 15px */
    border-radius: 50%;
    background: #C7F100; /* Updated color to #C7F100 */
    cursor: pointer;
    border: 1px solid #eaeaea;
}

.slider::-ms-fill-lower {
    background: #C7F100; /* Updated color to #C7F100 */
    border-radius: 10px;
}

.slider::-ms-fill-upper {
    background: #C7F100; /* Updated color to #C7F100 */
    border-radius: 10px;
}