/* ChatModal.module.css */
.trip-modal-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(15, 14, 14, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2000;
}

.trip-modal-content {
    margin-top: 10px;
    background-color: white;
    padding: 35px 20px 20px 20px;
    /* padding: 20px; */
    border-radius: 8px;
    max-width: 90%;
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

/* For larger desktop screens (min-width: 1024px), set max-width to 50% */
@media (min-width: 1024px) {
    .trip-modal-content {
        max-width: 50%;
    }
}


.trip-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-family: 'Roboto, sans-serif';
    color: #333;
    background-color: transparent;
    border-radius: 5px;
    border-width: 1px;
    border-color: #333;
    z-index: 2001;
}