.center {
    text-align: center;
}

.carInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.carInfoWrapper {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    background-color: #e6e6e6;
}

.cursorPointer {
    cursor: pointer;
}

.CarStationTitle {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700;
}

.CarStationTitleAndSmallFont {
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
}

.smallFont {
    font-size: 12px;
}

.largeFont {
    font-size: 20px;
}

.iconWrapper {
    width: 20px;
    height: 20px;
}

.calendarImage {
    width: 100%;
    height: 100%;
}

.dateInfoWrapper {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
}

.dateText {
    font-size: 15px;
}

.timeText {
    font-size: 14px;
    padding-top: 2px;
    margin-left: 6px;
}

.dateRangeWrapper {
    margin-left: 10px;
}

.dateTimeContainer {
    display: flex;
    flex-direction: column;
}

.input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.buttonContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.buttonBlack {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.addressIcon {
    object-fit: contain;
    height: 25px;
}

.card {
    background-color: #f9f9f9; /* Card background color */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    margin: 0 auto; /* Center the card */
}

.cardHeader {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.carImage {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 20px;
}

.containerImage {
    display: flex;
    justify-content: center;
}
