/* vehiclesStyles.module.css */
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 77px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f0f2f5;
}

.carGrid {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 50px;
}

.carGridMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 130px;
}

.card {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    height: 200px;
}

.cardMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    background-color: #FFFFFF;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.carImage {
    flex: 0 0 40%;
    width: 320px;
    height: 200px;
    object-fit: cover;
}

.cardMobile .carImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    padding-top: 0px;
    flex: 1;
}

.cardMobile .content {
    width: 100%;
}

.carType {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}

.cardMobile .carType {
    font-size: 14px;
}

.tripDetails {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;

}

.tripDetailsMobile {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}

.cardMobile .tripDetails {
    font-size: 12px;
}

.button {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #dedddd;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttonMobile  {
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #dedddd;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.buttonBook {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #C7F100;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.heading {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0 10px 0;
    text-align: center;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.dateText {
    font-size: 18px;
    color: #555;
}
.dataCar {
    font-size: 14px;
    color: #555;
    width: 90%;
}

.error {
    font-size: 18px;
    color: black;
    text-align: center;
}

.IconContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}
.seeDetails {
    width: 100px;
    justify-content: center;
    border: none;
    background-color: transparent;
    display: flex;
    margin-top: 5px;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
}

.icon {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.mapContainer {
    margin-top: 20px;
    width: 100%;
    height: calc(100vh - 50vh);
    display: flex;
    justify-content: center;
}

