.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    height: calc(100vh - 70px);
    background-color: #f0f2f5;
    overflow-y: auto; /* Ensures vertical scrolling */
    overflow-x: hidden;
    box-sizing: border-box;
}

.card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.title {
    font-size: 2em;
    color: #C7F100;
    margin-bottom: 10px;
}

.message {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
}

.tripDetails {
    text-align: left;
    margin-bottom: 20px;
}

.subTitle {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.detail {
    margin: 10px 0;
    font-size: 1em;
    color: #777;
}

.nextSteps {
    text-align: left;
}

.stepsList {
    list-style-type: none;
    padding: 0;
}

.step {
    margin: 10px 0;
    font-size: 1em;
    color: #777;
}

.button {
    background-color: #C7F100;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
}

.button:hover {
    background-color: #c1e702;
}

.newUserStep {
    color: rgb(253, 95, 95)
}

@media (max-width: 1200px) {
    .title {
        font-size: 1.8em;
    }

    .message {
        font-size: 1.1em;
    }

    .subTitle {
        font-size: 1.4em;
    }

    .detail,
    .step {
        font-size: 0.95em;
    }

    .button {
        font-size: 0.95em;
    }
}

@media (max-width: 992px) {
    .title {
        font-size: 1.6em;
    }

    .message {
        font-size: 1em;
    }

    .subTitle {
        font-size: 1.3em;
    }

    .detail,
    .step {
        font-size: 0.9em;
    }

    .button {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 1.4em;
    }

    .message {
        font-size: 0.9em;
    }

    .subTitle {
        font-size: 1.2em;
    }

    .detail,
    .step {
        font-size: 0.85em;
    }

    .button {
        font-size: 0.85em;
    }
}

@media (max-width: 600px) {
    .container {
        padding-bottom: 130px; /* Extra space at the bottom */
    }

    .title {
        font-size: 1.2em;
    }

    .message {
        font-size: 0.85em;
    }

    .subTitle {
        font-size: 1.1em;
    }

    .detail,
    .step {
        font-size: 0.8em;
    }

    .button {
        font-size: 0.8em;
    }
}
