@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Vertical Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Horizontal Scrollbar */
::-webkit-scrollbar {
  height: 8px;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(125, 125, 125, 0.5);
}

/* Handle active */
::-webkit-scrollbar-thumb:active {
  background: rgba(105, 105, 105, 0.7);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
