.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s;
    padding: 20px;
}

.modalContent {
    background-color: white;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transform: translateY(50px);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
}

.closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.modalImage {
    max-width: 80%;
    height: auto;
}

.paragraph {
    width: 80%;
    text-align: center;
    /* text-transform: uppercase; */
    font-size: 16px;
}

.title {
    text-align: center;
    font-size: 18px;
}

@keyframes unfoldIn {
    from {
        transform: scaleY(0.005) scaleX(0);
    }
    to {
        transform: scaleY(1) scaleX(1);
    }
}

@keyframes unfoldOut {
    from {
        transform: scaleY(1) scaleX(1);
    }
    to {
        transform: scaleY(0.005) scaleX(0);
    }
}

.modalEnter {
    animation: unfoldIn 0.5s forwards;
}

.modalExit {
    animation: unfoldOut 0.5s forwards;
}

@media (max-width: 600px) {
    .paragraph {
        font-size: 14px;
    }

    .title {
        font-size: 16px;
    }
}


