/* this file contains the styles for the booking page  && extension section in trips details page */
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 77px);
    background-color: #f0f2f5;
    overflow-y: auto;
    overflow-x: hidden;
}

.innerContainer {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    margin-bottom: 77px;
}

.scrollableContent {
    width: 100%;
    height: 100%;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    width: 150px;
    margin: 0 auto;
}

.title {
    font-size: 2em;
    color: #333;
}

.subtitle {
    font-size: 1.2em;
    color: #555;
}

.pickerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.clearButton, .searchButton {
    width: 60%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.clearButton {
    background-color: #e0e0e0;
    color: #333;
}

.clearButton:hover {
    background-color: #d0d0d0;
}

.searchButton {
    background-color: #C7F100;
    color: black;
}

.searchButton:hover {
    background-color: #b2d700;
}

@media (max-width: 600px) {
    .innerContainer {
        padding: 15px;
    }

    .title {
        font-size: 1.5em;
    }

    .subtitle {
        font-size: 1em;
    }

    .clearButton, .searchButton {
        width: 80%;
    }
}

/* STYLE FOR EXTENSION SECTION (tripDetails Page) */
.cancelButton {
    background-color: #e0e0e0;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    top: 10px;
    left: 10px;
}