/* confirmStyles.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f3f3f3;
}

.card {
    width: 100%;
    max-width: 600px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formGroup input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px; /* Ensures no zoom-in on mobile */
    width: calc(100% - 20px);
}

.details {
    display: flex;
    flex-direction: column;
}

.carDetails {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    height: 200px;
}

.carDetailsMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    background-color: #FFFFFF;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.carImage {
    flex: 0 0 40%;
    width: 320px;
    height: 200px;
    object-fit: cover;
}

.carDetailsMobile .carImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    padding-top: 0px;
    flex: 1;
}

.carType {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}

.iconContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    margin: 5px 0;
}

.dataCar {
    font-size: 14px;
    color: #555;
    width: 90%;
}

.icon {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.buttonContainerMobile {
    margin-bottom: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #C7F100;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #c1e702;
}

.rentalDetails {
    margin-top: 20px;
}

.priceItem {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
}

.mapContainer {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
}

.phoneGroup {
    display: flex;
    align-items: center;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: calc(100% - 20px);
    padding: 10px;
}

.phoneGroup span {
    padding-right: 10px;
    white-space: nowrap;
    margin: 0;
    font-size: 16px;
}

.phoneGroup input {
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    flex: 1;
    font-size: 16px;
    width: calc(100% - 20px);
}

.stripeElement {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: calc(100% - 20px);
    background-color: #fff;
}

.paymentMethod {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.useButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.useButton:hover {
    background-color: #45a049;
}

.anotherCardButton {
    margin-top: 20px;
    background-color: #dedddd;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.anotherCardButton:hover {
    background-color: #c1c1c1;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f3f3f3;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.cancelButton {
    background-color: #dedddd;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.confirmButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}