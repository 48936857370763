.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color:  #f8f8f8;
}

.header {
    width: 100%;
    padding: 20px 0;
    background-color: #f8f8f8;
    color: black;
    text-align: center;
}

.headerTitle {
    font-size: 24px;
    margin: 0;
}

.headerSubtitle {
    font-size: 16px;
    padding: 0 20px;
    margin: 10px 0;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #C7F100;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
}

.button:hover {
    background-color: #b2d700;
}

@media (max-width: 600px) {
    .button {
        padding: 8px 16px;
        font-size: 14px;
    }
}