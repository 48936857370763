.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 2001;
}

.carGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.card {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
    height: 200px;
}

.cardMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    background-color: #FFFFFF;
    border: 1px solid #E1E1E8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
}

.carImage {
    flex: 0 0 40%;
    width: 320px;
    height: 200px;
    object-fit: cover;
}

.cardMobile .carImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    padding-top: 0px;
    flex: 1;
}

.cardMobile .content {
    width: 100%;
}

.carType {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}

.cardMobile .carType {
    font-size: 14px;
}

.tripDetails {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
}

.tripDetailsMobile {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}

.cardMobile .tripDetails {
    font-size: 12px;
}

.buttonBook {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #C7F100;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.IconContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.seeDetails {
    display: flex;
    margin-top: 5px;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
}

.icon {
    width: 15px;
    height: 15px;
    object-fit: contain;
}
