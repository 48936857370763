/* ChatModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1500;
}

.modal-content {
    background-color: white;
    padding-top: 35px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    height: 85vh; /* Adjust modal height for better fit on mobile */
    max-height: 85vh; /* Ensure the modal doesn't overflow the screen */
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Allow scrolling if content overflows */
    margin: 0; /* Remove the bottom margin */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1600; /* Ensure the button is always clickable */
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        height: 80vh; /* Reduce height slightly for smaller mobile screens */
        max-height: 80vh; /* Ensures it's never taller than the viewport */
    }
}