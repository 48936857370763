.buttonBlack {
    background-color: #333;
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

.buttonBlack:hover {
    background-color: #555;
}

.buttonBlack:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.buttonBlack:active {
    transform: scale(0.98);
}
